import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import close from './../assets/icon/cross.svg'
import { getStations, getRegions, getDepartements, getDomaines } from './DataJson';

const SearchModal = ({ isOpen, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [stations, setStations] = useState([]);
  const [regions, setRegions] = useState([]);
  const [departements, setDepartements] = useState([]);
  const [domaines, setDomaines] = useState([]);

  // Charger les stations, les régions et les départements au montage du composant
  useEffect(() => {
    const loadData = async () => {
      try {
        const [stationsData, regionsData, departementsData, domainesData] = await Promise.all([
          getStations(),
          getRegions(),
          getDepartements(),
          getDomaines()
        ]);
        setStations(stationsData);
        setRegions(regionsData);
        setDepartements(departementsData);
        setDomaines(domainesData);
      } catch (error) {
        console.error('Erreur lors du chargement des données:', error);
      }
    };
    loadData();
  }, []);

  // Fonction pour normaliser le texte (enlever les tirets et les accents)
  const normalizeText = (text) => {
    if (!text) return ''; // Ajout d'une protection contre les valeurs undefined/null
    return text
      .toLowerCase()
      .normalize('NFD') // Décompose les caractères accentués
      .replace(/[\u0300-\u036f]/g, '') // Supprime les accents
      .replace(/-/g, ' ') // Remplace les tirets par des espaces
      .replace(/\s+/g, ' ') // Remplace les espaces multiples par un seul espace
      .trim();
  };

  // Gérer la recherche
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() === '') {
      setSearchResults([]);
      return;
    }

    const normalizedSearchTerm = normalizeText(value);

    // Recherche dans les stations avec vérification
    const stationResults = stations
      .filter(station => station && station.nom)
      .filter(station => 
        normalizeText(station.nom).includes(normalizedSearchTerm)
      ).map(station => ({
        ...station,
        type: 'station'
      }));

    // Recherche dans les régions avec vérification
    const regionResults = regions
      .filter(region => region && region.region)
      .filter(region => {
        const normalizedRegionName = normalizeText(region.region);
        return normalizedRegionName.includes(normalizedSearchTerm);
      }).map(region => ({
        id: region.region_ID,
        nom: region.region,
        type: 'region'
      }));

    // Recherche dans les départements
    const departementResults = departements
      .filter(dept => dept && dept.departement)
      .filter(dept => 
        normalizeText(dept.departement).includes(normalizedSearchTerm)
      ).map(dept => ({
        id: dept.departement_ID,
        nom: dept.departement,
        type: 'departement'
      }));

    // Recherche dans les domaines
    const domaineResults = domaines
      .filter(dom => dom && dom.domaine)
      .filter(dom => 
        normalizeText(dom.domaine).includes(normalizedSearchTerm)
      ).map(dom => ({
        id: dom.domaine_ID,
        nom: dom.domaine,
        type: 'domaine'
      }));

    setSearchResults([
      ...stationResults, 
      ...regionResults, 
      ...departementResults,
      ...domaineResults
    ]);
  };

  // Fonction pour formater l'URL selon le type
  const formatUrl = (item) => {
    switch(item.type) {
      case 'station':
        return `/Decouvrir-Station/${item.nom}`;
      case 'region':
        return `/comparer-et-trouver-votre-station-de-ski-ideale?regions=${item.id}`;
      case 'departement':
        return `/comparer-et-trouver-votre-station-de-ski-ideale?departements=${item.id}`;
      case 'domaine':
        return `/comparer-et-trouver-votre-station-de-ski-ideale?domaines=${encodeURIComponent(item.nom)}`;
      default:
        return '/';
    }
  };

  if (!isOpen) return null;

  return (
    <div className="search-modal">
      <div className="search-modal__content">
        <button className="search-modal__close" onClick={onClose}>
          <img src={close} alt="Fermer la recherche" />
        </button>
        
        <div className="search-modal__input-container">
          <input 
            type="text" 
            placeholder="Rechercher station, région, département, domaine..."
            className="search-modal__input"
            value={searchTerm}
            onChange={handleSearch}
            autoFocus
          />
        </div>

        <div className="search-modal__results">
          {searchResults.length > 0 ? (
            searchResults.map((item) => (
              <Link 
                key={`${item.type}-${item.id}`}
                to={formatUrl(item)}
                className="search-modal__result-item"
                onClick={onClose}
              >
                <div className="search-modal__result-name">
                  {item.nom}
                  <span className="search-modal__result-type">
                    {item.type === 'station' ? 'Station' : 
                     item.type === 'region' ? 'Région' : 
                     item.type === 'departement' ? 'Département' :
                     'Domaine'}
                  </span>
                </div>
              </Link>
            ))
          ) : (
            searchTerm && (
              <div className="search-modal__no-results">
                Aucune station, région, département ou domaine ne correspond à votre recherche
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchModal; 