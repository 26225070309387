import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Logo from './../assets/logo.png';
import btn from './../assets/icon/menu.svg'
import close from './../assets/icon/cross.svg'
import search from './../assets/icon/loupe-50.png'
import SearchModal from './SearchModal';


export default function Navbar() {

  //change nav color when scrolling
  const [color, setColor] = useState(false)
  const changeColor = () => {
    if (window.scrollY >= 25) {
      setColor(true)
    }
    else {
      setColor(false)
    }
  }

  window.addEventListener('scroll', changeColor)

  //animate burger menu
  const [isActive, setIsActive] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <nav className={color ? 'nav nav-bg' : 'nav'}>
      <div className='sous-nav'>

        <Link to="/" title='Accueil Mon Chamois'><img src={Logo} alt='logo site Mon Chamois avec un dessin de chamois'></img></Link>
        <button onClick={() => setIsActive(true)} className='menu-btn' aria-label="Ouvrir le menu">
          <img src={btn} alt='icone buger menu'></img>
        </button>
        <div className={`nav-links ${isActive ? "active" : ""}`}>
        <button onClick={() => setIsActive(false)} className='menu-btn' aria-label="Ouvrir le menu">
          <img src={close} alt='croix pour fermer le menu'></img>
        </button>
          <ul>
            <li> <Link  onClick={() => setIsActive(false)} to="/"> Accueil</Link></li>
            <li> <Link onClick={() => setIsActive(false)} to="/comparer-et-trouver-votre-station-de-ski-ideale"> Comparer</Link></li>
            <li> <Link onClick={() => setIsActive(false)} to="/About">Nous connaître</Link></li>
          </ul>
          <div className="search-icon" onClick={() => setIsSearchOpen(true)}>
            <img src={search} alt="Rechercher" />
          </div>
        </div>
      </div>
      
      <SearchModal 
        isOpen={isSearchOpen} 
        onClose={() => setIsSearchOpen(false)} 
      />
    </nav>

  )
} 