import React, { useState, useEffect} from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import GrilleDetail from '../components/GrilleDetail';
import { Helmet } from 'react-helmet-async';
import MultiRangeSlider from "multi-range-slider-react";
import { getDepartements, getRegions, getDomaines, getLabels, getStations } from '../components/DataJson'
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

// chargement des listes de valeurs
const regionList = getRegions();
const departementList = getDepartements();
const domaineList = getDomaines();
const labelList = getLabels();
const stationList = getStations();

// Liste valeur min max pour les sliders et les filtres
let varRemontMin = 1;
let varRemontMax = 110; 
let varNbPisteMin = 1;
let varNbPisteMax = 250;
let varHauteurMin = 500; 
let varHauteurMax = 4000;


const Listestation = () => {

//initialisation des constantes de filtres basées sur la mise à jour des URL
   const [searchParams, setSearchParams] = useSearchParams();

   const [selectedRegion, setSelectedRegion] = useState(
      searchParams.get('regions') ? searchParams.get('regions').split(',') : []
    );

   const [selectDepartement, setSelecteDepartement] = useState(
     searchParams.get('departements') ? searchParams.get('departements').split(',') : []
   );
   
   const [selectedDomaine, setSelectedDomaine] = useState(
      searchParams.get('domaines') ? searchParams.get('domaines').split(',') : []
   );   
   
    const [selectedLabel, setSelectedLabel] = useState(
      searchParams.get('labels') ? searchParams.get('labels').split(',') : []
    );

   const [rangeRemontMinValue, setrangeRemontMinValue] = useState(searchParams.get('remontMin') || [varRemontMin]);
   const [rangeRemontMaxValue, setrangeRemontMaxValue] = useState(searchParams.get('remontMaxValue') || [varRemontMax]);
   
   const [rangeNbPisteMinValue, setrangeNbPisteMinValue] = useState([varNbPisteMin]);
   const [rangeNbPisteMaxValue, setrangeNbPisteMaxValue] = useState([varNbPisteMax]);

   const [rangeAltitudeMinValue, setRangeAltitudeMinValue] = useState([varHauteurMin]);
   const [rangeAltidueMaxValue, setRangeAltitudeMaxValue] = useState([varHauteurMax]);
   
   const [selectedStation, setSelectedStation] = useState(
      searchParams.get('station') ? searchParams.get('station').split(',') : []
   );

   const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(() => {
      const updatedSelecteDepartements = searchParams.get('departements') ? searchParams.get('departements').split(',') : [];
      const selectDepartementAsIntegers = updatedSelecteDepartements.map((id) => Number(id));
      setSelecteDepartement(selectDepartementAsIntegers) ; 
      
      const updatedSelecteRegions = searchParams.get('regions') ? searchParams.get('regions').split(',') : [];
      const selectRegionAsIntegers = updatedSelecteRegions.map((id) => Number(id));
      setSelectedRegion(selectRegionAsIntegers) ; 
 
      setSelectedDomaine (searchParams.get('domaines') ? searchParams.get('domaines').split(',') : []);
      setSelectedStation (searchParams.get('stations') ? searchParams.get('stations').split(',') : []);
      
      setSelectedLabel (searchParams.get('labels') ? searchParams.get('labels').split(',') : []);
      
      setrangeRemontMinValue (searchParams.get('remontMin') ? searchParams.get('remontMin') : [varRemontMin]);
      setrangeRemontMaxValue (searchParams.get('remontMax') ? searchParams.get('remontMax') : [varRemontMax]);
      
      setrangeNbPisteMinValue (searchParams.get('nbpisteMin') ? searchParams.get('nbpisteMin') : [varNbPisteMin]);
      setrangeNbPisteMaxValue (searchParams.get('nbpisteMax') ? searchParams.get('nbpisteMax') : [varNbPisteMax]);

      setRangeAltitudeMinValue (searchParams.get('altidudeMin') ? searchParams.get('altidudeMin') : [varHauteurMin]);
      setRangeAltitudeMaxValue (searchParams.get('altitudeMax') ? searchParams.get('altitudeMax') : [varHauteurMax]);
 
    }, [searchParams]);


   const filterStations = stationList
      .filter((station) => {
      if (selectedRegion.length === 0) {
         return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
      } else {
         return selectedRegion.some((id) => station.region_ID === id);
      }
      })
      .filter((station) => {
         if (selectDepartement.length === 0) {
            return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
         } else {
            return selectDepartement.some((id) => station.departement_ID === id);
         }
      })
      .filter((station) => {
         if (selectDepartement.length === 0) {
            return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
         } else {
            return selectDepartement.some((id) => station.departement_ID === id);
         }
      })
      .filter((station) => {
         if (selectedDomaine.length === 0) {
            return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
         } else {
            return selectedDomaine.includes(station.domaine);
         }
      })
      .filter((station) => {
         if (selectedStation.length === 0) {
            return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
         } else {
            return selectedStation.includes(station.nom);
         }
      })
      .filter((station) => {
         if (selectedLabel.length === 0) {
            return true; // Si aucun filtre n'est sélectionné, on affiche tous les éléments
         } else {
            return selectedLabel.includes(station.label_station);
         }
      }) 
      .filter((station) => {
         const nbRemonteeMecaMin = station.nb_remontee_meca || varRemontMin;
         return nbRemonteeMecaMin >= rangeRemontMinValue;})
      .filter((station) => {
            const nbRemonteeMecaMax = station.nb_remontee_meca || varRemontMax;
            return nbRemonteeMecaMax <= rangeRemontMaxValue;})
      .filter((station) => {
         const nbpisteMin = station.nb_piste || varNbPisteMin;
         return nbpisteMin >= rangeNbPisteMinValue;})
      .filter((station) => {
         const nbpisteMax = station.nb_piste || varNbPisteMax;
         return nbpisteMax <=  rangeNbPisteMaxValue;})
      .filter((station) => {
         const altitudeMin = station.hauteur_min || varHauteurMin;
         return altitudeMin  >= rangeAltitudeMinValue;})
      .filter((station) => {
         const altitudeMax = station.hauteur_max || varHauteurMax;
         return altitudeMax <= rangeAltidueMaxValue;})
      
   ;
   

   const allFiltersSelected = selectedRegion.length + selectDepartement.length + selectedDomaine.length + selectedLabel.length + selectedStation.length
  

   const handleRegionChange = (eventRegion) => {
      const region_ID = parseInt(eventRegion.target.id);
      const newSelecteRegion = selectedRegion.includes(region_ID)
      ? selectedRegion.filter((r) => r !== region_ID)
      : [...selectedRegion, region_ID];
      
      const newParams = new URLSearchParams(searchParams);
      if (newSelecteRegion.length > 0) {
        newParams.set('regions', newSelecteRegion.join(','));
        
      } else {
        newParams.delete('regions');
      }
      setSearchParams(newParams);
    };
   
    const handleDepartementChange = (eventDepartement) => {
      const departement_ID = parseInt(eventDepartement.target.id);
      const newSelecteddepartements = selectDepartement.includes(departement_ID)
      ? selectDepartement.filter((r) => r !== departement_ID)
      : [...selectDepartement, departement_ID];
      
      const newParams = new URLSearchParams(searchParams);
      if (newSelecteddepartements.length > 0) {
        newParams.set('departements', newSelecteddepartements.join(','));
        
      } else {
        newParams.delete('departements');
      }
      setSearchParams(newParams);
    };
   
    const handleDomaineChange = (domaine) => {
      const newSelectedDomaine = selectedDomaine.includes(domaine)
      ? selectedDomaine.filter((r) => r !== domaine)
      : [...selectedDomaine, domaine];

      const newParams = new URLSearchParams(searchParams);
      if (newSelectedDomaine.length > 0) {
        newParams.set('domaines', newSelectedDomaine.join(','));
        
      } else {
        newParams.delete('domaines');
      }
      setSearchParams(newParams);
    };

    const handleStationChange = (station) => {
      const newSelectedStation = selectedStation.includes(station)
      ? selectedStation.filter((r) => r !== station)
      : [...selectedStation, station];

      const newParams = new URLSearchParams(searchParams);
      if (newSelectedStation.length > 0) {
        newParams.set('stations', newSelectedStation.join(','));

      } else {
        newParams.delete('stations');
      }
      setSearchParams(newParams);
    };

    const handleLabelChange = (label) => {
      const newselectedLabel = selectedLabel.includes(label)
      ? selectedLabel.filter((r) => r !== label)
      : [...selectedLabel, label];

      const newParams = new URLSearchParams(searchParams);
      if (newselectedLabel.length > 0) {
        newParams.set('labels', newselectedLabel.join(','));
        
      } else {
        newParams.delete('labels');
      }
      setSearchParams(newParams);
    };

    const handleRangeChange = (range, valueMin, valueMax) => {
      const newParams = new URLSearchParams(searchParams);     
      if (range ==='remont') {
         if (valueMin !== varRemontMin)  {newParams.set('remontMin', valueMin);}
         else { newParams.delete('remontMin');} 
      
         if (valueMax !== varRemontMax)  {newParams.set('remontMax', valueMax);}
         else { newParams.delete('remontMax');}
         }      
      else if (range ==='altitude') {
         if (valueMin !== varHauteurMin)  {newParams.set('altidudeMin', valueMin);}
         else { newParams.delete('altidudeMin');} 
      
         if (valueMax !== varHauteurMax)  {newParams.set('altitudeMax', valueMax);}
         else { newParams.delete('altitudeMax');}
         }      
      else if (range ==='nbpiste') {
         if (valueMin !== varNbPisteMin)  {newParams.set('nbpisteMin', valueMin);}
         else { newParams.delete('nbpisteMin');} 
      
         if (valueMax !== varNbPisteMax)  {newParams.set('nbpisteMax', valueMax);}
         else { newParams.delete('nbpisteMax');}
         }      
      
      setSearchParams(newParams);
    };

   // variables dédiées au scroll
      const [count, setCount] = useState(5);
      const [hasMore, setHasMore] = useState(true);
   
      const fetchMoreData = () => {
      if (count >= filterStations.length) {
         setHasMore(false);
         return;
      }
      setCount(prevCount => prevCount + 5);
      };




   return (
      <div className='listestation'>
         <Helmet>
            <title>Comparer et trouver votre station de ski idéale</title>
            <meta name="description" content="Notre comparateur vous permettre de trouverla station de vos rêves selon vos propres critères"></meta>
            <link rel="cannonical" href="https://www.monchamois.com/comparer-et-trouver-votre-station-de-ski-ideale" />
         </Helmet>

         <header className='header'>
            <div className='background'>
               <Navbar />
               <div className="textbox">
                  <h1>Comparer et trouver votre station de ski idéale</h1>
               </div>
            </div>
         </header>

         <button 
            className='filter-button-mobile'
            onClick={() => setIsModalOpen(true)}
         >
            Filtres
         </button>

         <div className={`filter-modal ${isModalOpen ? 'open' : ''}`}>
            <div className="filter-modal-content">
               <div className="filter-modal-header">
                  <button 
                     className="close-modal"
                     onClick={() => setIsModalOpen(false)}
                  >
                     ×
                  </button>
               </div>
               <div className='grilleStation'>
                  <div className='FiltreStation'>
                     <div className="enteteFilterMenu">
                        <span className='cancelFilterButton'> 
                           {allFiltersSelected > 0 && (
                              <button onClick={() => setSearchParams()}>
                                 Annuler filtre(s)
                              </button>
                           )}
                        </span>
                     </div>
                     <div className='filterType'>
                        <div className='headFilter'>
                           <span><h4>Région</h4></span>
                        </div>
                        <ul className="region-container">
                           {regionList.sort((a, b) => (a.region < b.region ? -1 : 1))
                              .map(region => (
                                 <li key={region.region_ID}>
                                    <input
                                       type="checkbox"
                                       name="region"
                                       id={region.region_ID}
                                       checked={selectedRegion.includes(region.region_ID)}
                                       onChange={handleRegionChange}
                                    />
                                    <label htmlFor={region.region_ID}>{region.region} </label>
                                 </li>
                              ))}
                        </ul>
                     </div>

                     <details className='filterType'>
                        <summary className='headFilter'>
                           <span><h4>Département</h4></span>
                        </summary>
                        <div className='filter-checkbox'  >
                           <ul className='filter-checkbox-ul'>
                                 {departementList.sort((a, b) => (a.departement_numero < b.departement_numero ? -1 : 1))
                                      .map(departement => (
                                         <li key={departement.departement_ID}>
                                            <input
                                               type="checkbox"
                                               name="departement"
                                               id={departement.departement_ID}
                                            checked={selectDepartement.includes(departement.departement_ID)}
                                            onChange={handleDepartementChange}
                                            />
                                            <label htmlFor={departement.departement_ID}>{departement.departement} ({departement.departement_numero})</label>
                                         </li>
                                      ))}
                           </ul>
                        </div>
                     </details>

                     <details className='filterType'>
                        <summary className='headFilter'>
                           <span><h4>Domaine</h4></span>
                        </summary>
                        <div className='filter-checkbox'>
                           <ul className='filter-checkbox-ul'>
                              {domaineList.sort((a, b) => (a.domaine < b.domaine ? -1 : 1))
                                    .map(domaine => (
                                       <li key={domaine.domaine}>
                                          <input
                                             type="checkbox"
                                             name="domaine"
                                             id={domaine.domaine}
                                             checked={selectedDomaine.includes(domaine.domaine)}
                                                onChange={() => handleDomaineChange(domaine.domaine)}
                                          />
                                          <label htmlFor={domaine.domaine_ID}>{domaine.domaine}</label>
                                       </li>
                                    ))}
                           </ul>
                        </div>
                     </details>

                     <details className='filterType'>
                        <summary className='headFilter'>
                           <span><h4>Station</h4></span>
                        </summary>
                        <div className='filter-checkbox'>
                           <ul className='filter-checkbox-ul'>
                                 {stationList.sort((a, b) => (a.nom < b.nom ? -1 : 1))
                                      .map(station => (
                                         <li key={station.nom}>
                                            <input
                                               type="checkbox"
                                               name="nom"
                                               id={station.nom}
                                               checked={selectedStation.includes(station.nom)}
                                                onChange={() => handleStationChange(station.nom)}
                                            />
                                            <label htmlFor={station.nom}>{station.nom}</label>
                                         </li>
                                      ))}
                           </ul>
                        </div>
                     </details>

                     <details className='groupe_Range'>
                        <summary className='headFilter'>
                           <span><h4>Altitude</h4></span><br />
                        </summary>
                        <div className='rangeAltitude'>
                           <MultiRangeSlider
                              step="50"
                              min={varHauteurMin}
                              max={varHauteurMax}
                              ruler="false"
                              minValue={rangeAltitudeMinValue}
                              maxValue={rangeAltidueMaxValue}
                              onChange={(e) => {
                                 handleRangeChange('altitude',e.minValue,e.maxValue);
                              }}
                           ></MultiRangeSlider>
                           <p>Entre {rangeAltitudeMinValue} m et {rangeAltidueMaxValue} m</p>

                        </div>
                     </details>

                     <details className='groupe_Range'>
                        <summary className='headFilter'>
                           <span><h4>Nombre de piste</h4></span>
                        </summary>
                        <div className='rangeNbPisteMinValue'>
                           <MultiRangeSlider
                              min={varNbPisteMin}
                              max={varNbPisteMax}
                              ruler="false"
                              minValue={rangeNbPisteMinValue}
                              maxValue={rangeNbPisteMaxValue}
                              onChange={(e) => {
                                 handleRangeChange('nbpiste',e.minValue,e.maxValue);
                              }}
                           ></MultiRangeSlider>
                           <p>Entre {rangeNbPisteMinValue} et {rangeNbPisteMaxValue} pistes</p>
                        </div>
                     </details>

                     <details className='groupe_Range'>
                        <summary className='headFilter'>
                           <span><h4>Remontée mécanique</h4></span>
                        </summary>

                        <div className='rangeNbRemonte'>
                           <MultiRangeSlider
                              ruler="false"
                              min={varRemontMin}
                              max={varRemontMax}
                              minValue={rangeRemontMinValue}
                              maxValue={rangeRemontMaxValue}
                              onChange={(e) => {
                                 handleRangeChange('remont',e.minValue,e.maxValue);
                              }}
                           ></MultiRangeSlider>
                           <p>
                              Entre {rangeRemontMinValue} et {rangeRemontMaxValue} remontées
                           </p>
                        </div>
                     </details>

                        <details className='filterType'>
                        <summary className='headFilter'>
                           <span><h4>Label</h4></span>
                        </summary>
                        <div className='filter-checkbox'>
                           <ul className='filter-checkbox-ul'>
                             {labelList.sort((a, b) => (a.label_station < b.label_station ? -1 : 1))
                                  .map(label => (
                                     <li key={label.label_station}>
                                        <input
                                           type="checkbox"
                                           name="label"
                                           id={label.label_station}
                                           checked={selectedLabel.includes(label.label_station)}
                                              onChange={() => handleLabelChange(label.label_station)}
                                        />
                                        <label htmlFor={label.label_station}>{label.label_station}</label>
                                     </li>
                                  ))}
                           </ul>
                        </div>
                     </details>         

                  </div>
               </div>
            </div>
         </div>

         <div className='grilleStation main-filters'>
            
            <div className='FiltreStation'>
               <div className="enteteFilterMenu">
                  
                  <span className='cancelFilterButton'> {allFiltersSelected > 0 && (<button onClick={() => setSearchParams()}>Annuler filtre(s)</button>)}</span>
               </div>
               <div className='filterType'>
                  <div className='headFilter'>
                     <span><h4>Région</h4></span>
                  </div>
                  <ul className="region-container">
                     {regionList.sort((a, b) => (a.region < b.region ? -1 : 1))
                        .map(region => (
                           <li key={region.region_ID}>
                              <input
                                 type="checkbox"
                                 name="region"
                                 id={region.region_ID}
                                 checked={selectedRegion.includes(region.region_ID)}
                                 onChange={handleRegionChange}
                              />
                              <label htmlFor={region.region_ID}>{region.region} </label>
                           </li>
                        ))}
                  </ul>
               </div>

               <details className='filterType'>
                  <summary className='headFilter'>
                     <span><h4>Département</h4></span>
                  </summary>
                  <div className='filter-checkbox'  >
                     <ul className='filter-checkbox-ul'>
                           {departementList.sort((a, b) => (a.departement_numero < b.departement_numero ? -1 : 1))
                                .map(departement => (
                                   <li key={departement.departement_ID}>
                                      <input
                                         type="checkbox"
                                         name="departement"
                                         id={departement.departement_ID}
                                      checked={selectDepartement.includes(departement.departement_ID)}
                                      onChange={handleDepartementChange}
                                      />
                                      <label htmlFor={departement.departement_ID}>{departement.departement} ({departement.departement_numero})</label>
                                   </li>
                                ))}
                     </ul>
                  </div>
               </details>

               <details className='filterType'>
                  <summary className='headFilter'>
                     <span><h4>Domaine</h4></span>
                  </summary>
                  <div className='filter-checkbox'>
                     <ul className='filter-checkbox-ul'>
                        {domaineList.sort((a, b) => (a.domaine < b.domaine ? -1 : 1))
                              .map(domaine => (
                                 <li key={domaine.domaine}>
                                    <input
                                       type="checkbox"
                                       name="domaine"
                                       id={domaine.domaine}
                                       checked={selectedDomaine.includes(domaine.domaine)}
                                          onChange={() => handleDomaineChange(domaine.domaine)}
                                    />
                                    <label htmlFor={domaine.domaine_ID}>{domaine.domaine}</label>
                                 </li>
                              ))}
                     </ul>
                  </div>
               </details>

               <details className='filterType'>
                  <summary className='headFilter'>
                     <span><h4>Station</h4></span>
                  </summary>
                  <div className='filter-checkbox'>
                     <ul className='filter-checkbox-ul'>
                           {stationList.sort((a, b) => (a.nom < b.nom ? -1 : 1))
                                .map(station => (
                                   <li key={station.nom}>
                                      <input
                                         type="checkbox"
                                         name="nom"
                                         id={station.nom}
                                         checked={selectedStation.includes(station.nom)}
                                          onChange={() => handleStationChange(station.nom)}
                                      />
                                      <label htmlFor={station.nom}>{station.nom}</label>
                                   </li>
                                ))}
                     </ul>
                  </div>
               </details>

               <details className='groupe_Range'>
                  <summary className='headFilter'>
                     <span><h4>Altitude</h4></span><br />
                  </summary>
                  <div className='rangeAltitude'>
                     <MultiRangeSlider
                        step="50"
                        min={varHauteurMin}
                        max={varHauteurMax}
                        ruler="false"
                        minValue={rangeAltitudeMinValue}
                        maxValue={rangeAltidueMaxValue}
                        onChange={(e) => {
                           handleRangeChange('altitude',e.minValue,e.maxValue);
                        }}
                     ></MultiRangeSlider>
                     <p>Entre {rangeAltitudeMinValue} m et {rangeAltidueMaxValue} m</p>

                  </div>
               </details>

               <details className='groupe_Range'>
                  <summary className='headFilter'>
                     <span><h4>Nombre de piste</h4></span>
                  </summary>
                  <div className='rangeNbPisteMinValue'>
                     <MultiRangeSlider
                        min={varNbPisteMin}
                        max={varNbPisteMax}
                        ruler="false"
                        minValue={rangeNbPisteMinValue}
                        maxValue={rangeNbPisteMaxValue}
                        onChange={(e) => {
                           handleRangeChange('nbpiste',e.minValue,e.maxValue);
                        }}
                     ></MultiRangeSlider>
                     <p>Entre {rangeNbPisteMinValue} et {rangeNbPisteMaxValue} pistes</p>
                  </div>
               </details>

               <details className='groupe_Range'>
                  <summary className='headFilter'>
                     <span><h4>Remontée mécanique</h4></span>
                  </summary>

                  <div className='rangeNbRemonte'>
                     <MultiRangeSlider
                        ruler="false"
                        min={varRemontMin}
                        max={varRemontMax}
                        minValue={rangeRemontMinValue}
                        maxValue={rangeRemontMaxValue}
                        onChange={(e) => {
                           handleRangeChange('remont',e.minValue,e.maxValue);
                        }}
                     ></MultiRangeSlider>
                     <p>
                        Entre {rangeRemontMinValue} et {rangeRemontMaxValue} remontées
                     </p>
                  </div>
               </details>

                  <details className='filterType'>
                  <summary className='headFilter'>
                     <span><h4>Label</h4></span>
                  </summary>
                  <div className='filter-checkbox'>
                     <ul className='filter-checkbox-ul'>
                       {labelList.sort((a, b) => (a.label_station < b.label_station ? -1 : 1))
                            .map(label => (
                               <li key={label.label_station}>
                                  <input
                                     type="checkbox"
                                     name="label"
                                     id={label.label_station}
                                     checked={selectedLabel.includes(label.label_station)}
                                        onChange={() => handleLabelChange(label.label_station)}
                                  />
                                  <label htmlFor={label.label_station}>{label.label_station}</label>
                               </li>
                            ))}
                     </ul>
                  </div>
               </details>         

            </div>
            <div className='listeStationGrid'>
               <InfiniteScroll
                  dataLength={count}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  //endMessage={<p>Plus de station à afficher.</p>}
               >
               
                  {filterStations
                     .slice(0,count)
                     .sort((a, b) => (a.nom < b.nom ? -1 : 1))
                     .map((station) => (
                        <GrilleDetail station={station} key={station.id} />

                     ))}
               </InfiniteScroll>      
            </div>
         </div>
         <Footer />
      </div>

   );


};

export default Listestation;